import { render, staticRenderFns } from "./LayoutHeader.vue?vue&type=template&id=a7a6b048&scoped=true&"
import script from "./LayoutHeader.vue?vue&type=script&lang=js&"
export * from "./LayoutHeader.vue?vue&type=script&lang=js&"
import style0 from "./LayoutHeader.vue?vue&type=style&index=0&id=a7a6b048&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7a6b048",
  null
  
)

export default component.exports