import { render, staticRenderFns } from "./RightView.vue?vue&type=template&id=2ff04641&scoped=true&"
import script from "./RightView.vue?vue&type=script&lang=js&"
export * from "./RightView.vue?vue&type=script&lang=js&"
import style0 from "./RightView.vue?vue&type=style&index=0&id=2ff04641&prod&scoped=true&lang=less&"
import style1 from "./RightView.vue?vue&type=style&index=1&id=2ff04641&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff04641",
  null
  
)

export default component.exports